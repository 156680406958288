import React, {useState} from "react"
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Layout from "../../components/layout"
import WebFont from 'webfontloader';
import SEO from "../../components/seo"
import {navigate} from "gatsby"
import NewCard from '../../components/WidgetComponents/NewCard.js'
import AppBar from '../../components/WidgetComponents/AppBar.js'
import DemoCard from '../../components/WidgetComponents/DemoCard.js'
import Grow from '@material-ui/core/Grow';
import "firebase/auth";
// import firebase from 'firebase/app';
import Loader from '../../components/WidgetComponents/Loader'
import Button from '@material-ui/core/Button'
import {useAuthState} from 'react-firebase-hooks/auth';
import {useDocument} from 'react-firebase-hooks/firestore';
import {auth, firestore} from '../../utils/firebase';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// const firebase = getFirebase();
// import useWindowSize from 'react-use/lib/useWindowSize'
// import Confetti from 'react-confetti'

let fontsToLoad = ['Work Sans', 'Lobster']

if (typeof window !== `undefined`) {
  // getFirebase();
  WebFont.load({
    google: {
      families: fontsToLoad.map(font => font + ':lighter,normal,bold,bolder')
    }
  });
}

function Page(props) {
  const [firestoreValue, firestoreLoading, firestoreError] = useDocument(firestore.doc('users/' + props.userID), {
    snapshotListenOptions: {
      includeMetadataChanges: true
    }
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let userBrands = []

  function newBrand() {
    if (userBrands.length < 2) {
      window
        .analytics
        .track("Clicked New Brand Button", {existing_brands: userBrands.length});
      navigate('/my/create')
    } else {
      handleClickOpen()
    }
  }

  function FormDialog(props) {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState(props.email)
    const [submitted, setSubmitted] = useState(false)

    function getMoreBrands() {
      window
        .analytics
        .track("Requested More Brands", {
          // "first_name": userFirstName,
          // "last_name": userLastName,
          "email": props.email
        });
      window
        .analytics
        .identify(props.userID, {
          contact_email: email,
          requested_brands: true
        });
      setSubmitted(true)
    }

    let submittedDialog = (<Grid container="container" direction='column' justify="center" alignItems="center" style={{
        padding: '30px'
      }}>
      <DialogTitle id="form-dialog-title" style={{
          fontWeight: 'bold'
        }}>{"Great, be in touch soon. "}
        <span role="img" aria-label="Avatar">😁</span>
      </DialogTitle>
      <Grid container="container" direction='column' justify="center" alignItems="center" style={{
          padding: '0px 0px 15px'
        }}>
        <Button onClick={handleClose} size='large' color="" variant='outlined' style={{
            margin: '0px 0px 0px'
          }}>
          Sounds Good
        </Button>
      </Grid>
    </Grid>)

    let unsubmittedDialog = <Grid container="container" direction='column' justify="center" alignItems="center" style={{
        padding: '30px'
      }}>
      <DialogTitle id="form-dialog-title" style={{
          fontWeight: 'bold'
        }}>Want more brands?</DialogTitle>
      <DialogContent>
        <DialogContentText style={{
            textAlign: 'center'
          }}>
          Let's connect and find a custom plan that fits your needs.
        </DialogContentText>
        <Grid container="container" direction='column' justify="center" alignItems="center" style={{
            // width: '300px'
          }}>
          <Grid style={{
              width: '300px',
              padding: '10px 0px 25px'
            }}>
            <TextField onChange={event => setFirstName(event.target.value)} variant='outlined' margin="normal" id="firstName" label="First Name" type="text" fullWidth="fullWidth" style={{
                margin: '15px 0px'
              }}/>
            <TextField onChange={event => setLastName(event.target.value)} variant='outlined' margin="normal" id="lastName" label="Last Name" type="text" fullWidth="fullWidth" style={{
                margin: '15px 0px'
              }}/>
            <TextField onChange={event => setEmail(event.target.value)} variant='outlined' margin="normal" id="email" defaultValue={props.email} label="Email Address" type="email" fullWidth="fullWidth" style={{
                margin: '15px 0px 5px'
              }}/>
          </Grid>
        </Grid>
      </DialogContent>
      <Grid container="container" direction='column' justify="center" alignItems="center" style={{
          padding: '0px 0px 15px'
        }}>
        <Button disabled={firstName == '' || lastName == '' || email == ''} onClick={getMoreBrands} size='large' color="secondary" variant='contained' style={{
            margin: '0px 0px 8px'
          }}>
          Get More Brands
        </Button>
        <Button onClick={handleClose} color="primary" style={{
            color: 'grey',
            margin: '8px 0px 0px'
          }}>
          Never mind
        </Button>
      </Grid>
    </Grid>

    return (<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      {
        (submitted)
          ? submittedDialog
          : unsubmittedDialog
      }
    </Dialog>);
  }
  if (firestoreLoading) {
    return <Loader/>
  }
  if (firestoreError) {
    navigate('/')
    return <Loader/>
  }
  if (typeof firestoreValue.data() !== 'undefined') {
    userBrands = firestoreValue.data()['brands'];
    window
      .analytics
      .identify(props.userID, {num_brands: userBrands.length});
    // window.analytics.page('Dashboard');
    return (<Grid container="container" direction='column' justify="center" alignItems="center" style={{
        backgroundColor: '',
        minHeight: '100vh'
      }}>
      <Grid container="container" direction='column' justify="center" alignItems="center" style={{
          padding: '100px 20px 0px 20px'
        }}>
        <Grow in="in">
          <Grid item="item">
            <Typography style={{
                fontFamily: 'Work Sans',
                fontSize: '30px',
                fontWeight: 'bold'
              }}>{'My Brands'}</Typography>
          </Grid>
        </Grow>
        <Grow in="in">
          <Grid item="item">
            <Grid container="container" direction='row' justify="center" alignItems="center" style={{
                paddingTop: '20px'
              }}>
              <Grid onClick={newBrand} style={{
                  margin: 'calc(10px + 1vw)'
                }}>
                <NewCard/>
              </Grid>
              {userBrands && userBrands.map(brandObject => <DemoCard brand={brandObject} userID={props.userID}/>)}
            </Grid>
          </Grid>
        </Grow>
        <Grid container="container" direction="row" justify="center" alignItems="center">
          <Button color='inherit' size='large' onClick={event => window.open('https://solomongomez.com')} style={{
              margin: '60px 15px 30px',
              color: 'grey',
              // textTransform: 'none'
            }}>
            Made by Solomon Gomez</Button>
        </Grid>
      </Grid>
      <FormDialog email={props.email} userID={props.userID}/>
    </Grid>)
  }
  navigate('/')
  return <Loader/>
}
function Home({
  location
}, props) {
  const [authUser, authLoading, authError] = useAuthState(auth);
  // const [celebrateDialogOpen, setCelebrateDialogOpen] = useState(location.state.brandCreated);
  // const {width, height} = useWindowSize()

  // if (location) {
  //   console.log('Brand Created')
  // }
  //
  // const handleCelebrateDialogClose = () => {
  //   setCelebrateDialogOpen(false);
  // };

  // let celebrateDialog = (<Dialog open={celebrateDialogOpen} onClose={handleCelebrateDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
  //   <DialogContent style={{
  //       margin: '40px 20px 35px 20px'
  //     }}>
  //     <Grid container="container" direction="column" justify="center" alignItems="center" style={{
  //         backgroundColor: ''
  //       }}>
  //       <Typography id="share-dialog" style={{
  //           textAlign: "center",
  //           fontSize: '40px',
  //           fontWeight: 'bold',
  //           fontFamily: 'Work Sans',
  //           margin: '-5px 0px 5px 0px',
  //           lineHeight: 1
  //         }}>Looking good!
  //         <span role="img" aria-label="Avatar">🎉</span>
  //       </Typography>
  //       <Typography id="share-dialog" style={{
  //           textAlign: "center",
  //           color: '#7a7a7a',
  //           fontSize: '30px',
  //           fontWeight: '600',
  //           fontFamily: 'Work Sans',
  //           margin: '10px 0px 10px 0px',
  //           lineHeight: 1
  //         }}>Congrats, you just created a new style guide for your brand.</Typography>
  //       <Button size='large' variant='contained' color='secondary' onClick={event => navigate(location.state.brandURL)} style={{
  //            color: 'grey',
  //           margin: '15px 10px 10px'
  //         }}>Check Out My Brand</Button>
  //     </Grid>
  //   </DialogContent>
  // </Dialog>)

  if (authLoading) {
    console.log('Authentication Loading')
    return (<Loader/>);
  }
  if (authError) {
    console.log('Authentication Error')
    navigate('/')
    return <Loader/>
  }
  if (authUser) {
    console.log('User Authenticated')
    window
      .analytics
      .identify(authUser.uid, {
        // name: authUser.displayName,
        // email: authUser.email
      });
    return (<Layout>
      <SEO title="My Dashboard"/>
      <Page userID={authUser.uid} email={authUser.email}/>
      <AppBar/>
    </Layout>);
  }
  console.log('No User Authenticated')
  navigate('/')
  return <Loader/>
}

export default Home

// Share your fonts and colour palette with designers, developers, marketers and everyone who's helping you build your brand.
