import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
// import Img from "gatsby-image";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import WebFont from 'webfontloader';

// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import WorkSans from 'typeface-work-sans';
// import Chip from '@material-ui/core/Chip';

import {navigate} from "gatsby"

// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import {useTheme} from '@material-ui/core/styles';

// import Layout from "../components/layout";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
// import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {firebase, firestore} from '../../utils/firebase';
// import firebaseApp from '@firebase/app';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert';
import planeAnimation from '../../images/paper-plane.gif';

import {CodeBlock, dracula} from 'react-code-blocks'

// import {
//   FacebookShareButton,
//   FacebookIcon,
//   TwitterShareButton,
//   TwitterIcon,
//   LinkedinShareButton,
//   LinkedinIcon,
//   EmailShareButton,
//   EmailIcon
// } from "react-share";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props}/>;
}

// const firebase = getFirebase();

const useStyles = makeStyles(theme => ({
  card: {
    // backgroundColor: '#f50057',
    width: '330px',
    // minHeight: '400px',
    margin: 'calc(10px + 1vw)',
    padding: '5px',
    boxShadow: '0 5px 20px rgba(0,0,0,0.15)',
    transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
    borderRadius: '30px',
    '&:hover': {
      boxShadow: '0 8px 20px 5px rgba(0,0,0,0.2)',
      // cursor: 'pointer'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      backgroundColor: '',
      width: '80vw',
      // minHeight: '250px',
      // margin: '20px',
    }
  },
  innerCardContainer: {
    backgroundColor: '',
    padding: '20px 20px',
    minHeight: '200px',
    // [
    //   theme
    //     .breakpoints
    //     .down('sm')
    // ]: {
    //   backgroundColor: '',
    //   width: '330px',
    //   minHeight: '250px',
    //    margin: '20px',
    // },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      backgroundColor: '',
      margin: '-20px 0px',
      minHeight: '50px',
      width: '80vw'
    }
  },
  heading: {
    // color: '#212121',
    padding: '15px',
    fontFamily: 'Work Sans',
    fontWeight: 800,
    fontSize: 35,
    lineHeight: 1,
    // color: 'white',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: 30,
      // paddingBottom: '20px'
    }
  },
  detail: {
    padding: '8px'
  },
  chip: {
    margin: '5px'
  },
  button: {
    // margin: '8px 0px 0px'
  },
  icon: {
    padding: '10px',
    backgroundColor: '',
    // border: '1px solid #e6e6e6',
    borderRadius: '100px',
    margin: '0px 8px',
    color: '#c2c2c2',
    transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      color: '#949494',
      // backgroundColor: '#ffedf4',
      cursor: 'pointer'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '0px 10px'
    }
  },
  moreButton: {
    color: '#a6a6a6',
    margin: '20px 20px 0px 0px',
    transition: 'all 0.5s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      color: '#545454',
      // backgroundColor: '#ffedf4',
      cursor: 'pointer'
    }
  },
  shareIcon: {
    margin: '10px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '5px'
    }
  },
  codeBlockStyle: {
    minWidth: '250px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      minWidth: '0px'
    }
  }
}));

export default function ExampleCard(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const openMoreMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // const theme = useTheme();

  // const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  if (typeof window !== `undefined`) {
    WebFont.load({
      google: {
        families: [props.brand.font + ':lighter,normal,bold,bolder']
      }
    });
  }

  const classes = useStyles();

  function deleteBrand() {
    const db = firestore

    // db
    //   .collection("brands")
    //   .doc(props.brand.id)
    //   .delete()
    console.log(props.brand.id)

    db
      .collection("brands")
      .doc(props.brand.id)
      .delete()
      .then(function() {
        console.log("Document successfully deleted!");
        // handleClose()
      })
      .catch(function(error) {
        console.error("Error removing document: ", error);
      });

    db
      .collection("users")
      .doc(props.userID)
      .update({
        brands: firebase
          .firestore
          .FieldValue
          .arrayRemove(props.brand)
      })
      .then(function() {
        console.log("Done");
        window
          .location
          .reload()
        // handleClose()
      })

    // navigate('/my/dashboard')
  }

  const launchBrand = () => {
    navigate("/brand?" + props.brand.id, {
      state: {
        creator: 'Yes'
      }
    })
    // props.setOpenCompany(props.drinkData)
    // props.openCompanyDialog()
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleComingSoonClose = () => {
    setOpen(false);
  };

  const handleShareDialogOpen = () => {
    setShareDialogOpen(true);
  };

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
  };

  // let finalDialog = (<Dialog open={open} onClose={handleComingSoonClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
  //   <DialogTitle id="alert-dialog-title">Coming Soon</DialogTitle>
  //   <DialogContent>
  //     <DialogContentText id="alert-dialog-description">
  //       Download your brand as a PDF or image.
  //     </DialogContentText>
  //   </DialogContent>
  //   <DialogActions>
  //     <Button onClick={handleComingSoonClose} color="secondary">
  //       Sounds Good
  //     </Button>
  //   </DialogActions>
  // </Dialog>)

  // let formattedPalette = props
  //   .brand
  //   .palette
  //   .map(color => color + "',\n\t\t'")

  // let code = "{\n\tname: '" + props.brand.name + "'\n\tfont: '" + props.brand.font + "'\n\tpalette: [\n\t\t'" + props
  //   .brand
  //   .palette[0] + "',\n\t\t'" + props
  //   .brand
  //   .palette[1] + "',\n\t\t'" + props
  //   .brand
  //   .palette[2] + "',\n\t\t'" + props
  //   .brand
  //   .palette[3] + "',\n\t\t'" + props
  //   .brand
  //   .palette[4] + "'," + "\n\t]" + " \n}"

  // ${props.brand.palette.map(color => "\"" + color + "\", \n     ")}

  let code = `{
    name: "${props
    .brand
    .name}",
    font: "${props
    .brand
    .font}",
    palette: [
      ${props
    .brand
    .palette[0] && "  \"" + props
    .brand
    .palette[0] + "\""},${props
    .brand
    .palette[1] === undefined
      ? ''
      : "\n        \"" + props
        .brand
        .palette[1] + "\""}${props
        .brand
        .palette[2] === undefined
          ? ''
          : ",\n        \"" + props
            .brand
            .palette[2] + "\""}${props
            .brand
            .palette[3] === undefined
              ? ''
              : ",\n        \"" + props
                .brand
                .palette[3] + "\""}${props
                .brand
                .palette[4] === undefined
                  ? ''
                  : ",\n        \"" + props
                    .brand
                    .palette[4] + "\""}
    ]
}
`

  let language = 'javascript'

  let finalDialog = (<Dialog open={open} onClose={handleComingSoonClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">{props.brand.name + ' in JSON'}</DialogTitle>
    <DialogContent className={classes.codeBlockStyle}>
      <CodeBlock text={code} language={language} showLineNumbers={true} theme={dracula} wrapLines="wrapLines" codeBlock="codeBlock"/>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleComingSoonClose} color="secondary">
        Done
      </Button>
    </DialogActions>
  </Dialog>)

  function copyToClipboard(event, text) {
    navigator
      .clipboard
      .writeText(text)
      .then(function() {
        setSnackbarOpen(true);
      }, function() {
        /* clipboard write failed */
      });
  }

  let snackbar = (<Snackbar open={snackbarOpen} autoHideDuration={1000} onClose={closeSnackbar}>
    <Alert onClose={closeSnackbar} style={{
        backgroundColor: '#4db884'
      }}>
      Success, link copied!
    </Alert>
  </Snackbar>)

  const shareUrl = "https://quickstyle.app/brand?" + props.brand.id
  // const title = 'QuickStyle'

  // let shareIcons = (<Grid container="container" direction="row" justify="center" alignItems="flex-end" style={{
  //     backgroundColor: '',
  //      height: '80px',
  //      paddingBottom: '0px'
  //   }}>
  //   <FacebookShareButton className={classes.shareIcon} url={shareUrl} quote={title}>
  //     <FacebookIcon size={50} round="round"/>
  //   </FacebookShareButton>
  //   <TwitterShareButton className={classes.shareIcon} url={shareUrl} title={title}>
  //     <TwitterIcon size={50} round="round"/>
  //   </TwitterShareButton>
  //   <LinkedinShareButton className={classes.shareIcon} url={shareUrl}>
  //     <LinkedinIcon size={50} round="round"/>
  //   </LinkedinShareButton>
  //   <EmailShareButton className={classes.shareIcon} url={shareUrl} subject={title} body="body">
  //     <EmailIcon size={50} round="round"/>
  //   </EmailShareButton>
  // </Grid>)

  let mailToArray = [
    'mailto:?subject=',
    props.brand.name,
    ' Brand Style Guide',
    '&body=',
    'Check out the ',
    props.brand.name,
    ' brand style guide: ',
    shareUrl
  ]
  let mailToLink = mailToArray.join('')

  // let mailToLink = 'mailto:?subject=' + props.brand.name + ' Brand Style Guide' + '&body=' + 'Check out the ' + props.brand.name + ' brand style guide: ' + shareUrl

  let shareDialog = (<Dialog open={shareDialogOpen} onClose={handleShareDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogContent style={{
        margin: '20px 0px 30px 0px'
      }}>
      <Grid container="container" direction="column" justify="center" alignItems="center" style={{
          backgroundColor: ''
        }}>
        <Typography id="share-dialog" style={{
            textAlign: "center",
            color: '#7a7a7a',
            fontSize: '30px',
            fontWeight: 'bold',
            fontFamily: props.brand.font,
            margin: '5px 0px 10px 0px',
            lineHeight: 1
          }}>{props.brand.name}</Typography>
        <Grid container="container" direction="column" justify="center" alignItems="center" style={{
            backgroundColor: ''
          }}>
          <img id='mockup' alt='Product Mockup' src={planeAnimation} style={{
              objectFit: 'cover',
              width: '200px',
              height: '100px'
              // backgroundColor: 'red'
            }}/>
        </Grid>
        <Typography id="share-dialog" style={{
            textAlign: "center",
            fontSize: '40px',
            fontWeight: 'bold',
            fontFamily: 'Work Sans',
            margin: '-5px 0px 5px 0px',
            lineHeight: 1
          }}>Share your Brand</Typography>
        <Typography id="alert-dialog-description" style={{
          padding: '12px 0px 10px 0px',
          margin: '20px 20px 0px',
            border: '1px solid #e6e6e6',
            borderRadius: '5px',
            lineHeight: 1.2,
            textAlign: 'center'
          }}>
          {shareUrl}
        </Typography>
        <Grid container="container" direction="row" justify="center" alignItems="center" style={{
            paddingTop: '20px'
          }}>
          <Button variant='outlined' color='secondary' onClick={event => copyToClipboard(event, shareUrl)} style={{
              // color: 'grey',
              margin: '5px 10px 10px'
            }}>Copy Link</Button>
          <Button variant='outlined' color='secondary' onClick={event => window.open(mailToLink)} style={{
              // color: 'grey',
              margin: '5px 10px 10px'
            }}>Send in Email</Button>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>)

  // let drinkImageUrl = `https://logo.clearbit.com/${props.drinkData.domain}?size=80`
  let iconBar = (<Grid container="container" direction="row" justify="center" alignItems="center" style={{
      padding: '0px 0px 30px',
      backgroundColor: '',
      // height: '120px',
      marginTop: '-20px',
      // margin: '40px 18px 0px 0px'
    }}>
    <OpenInNewRoundedIcon onClick={launchBrand} className={classes.icon} fontSize='large'/>
    <CodeRoundedIcon onClick={handleClickOpen} className={classes.icon} fontSize='large'/>
    <SendRoundedIcon onClick={handleShareDialogOpen} className={classes.icon} fontSize='large'/>
  </Grid>)

  return (<Card className={classes.card} variant='' elevation='0'>
    <Grid container="container" direction="column" justify="space-between" alignItems="center" style={{
        backgroundColor: '',
        minHeight: '400px'
      }}>
      <Grid container="container" direction="column" justify="center" alignItems="flex-end" style={{
          backgroundColor: '',
          // height: '80px',
          // margin: '40px 18px 0px 0px'
        }}>
        <MoreVertIcon className={classes.moreButton} aria-controls="simple-menu" aria-haspopup="true" onClick={openMoreMenu} fontSize='large'/>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted="keepMounted" open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={deleteBrand}>Delete Brand</MenuItem>
        </Menu>
      </Grid>
      <Grid className={classes.innerCardContainer} container="container" direction="column" justify="center" alignItems="center">
        <Typography onClick={launchBrand} align='center' utterBottom="utterBottom" variant="h5" component="h2" style={{
            padding: '15px',
            paddingTop: '0px',
            fontFamily: props.brand.font,
            fontWeight: 800,
            fontSize: 35,
            lineHeight: 1,
            wordWrap: "break-word",
            // backgroundColor: 'red',
            width: '250px',
            cursor: 'pointer'
            // width: '200px'
          }}>
          {props.brand.name}
        </Typography>
        <Grid container="container" direction="row" justify="center" alignItems="center" style={{
            // width: '200px'
          }}>{
            props
              .brand
              .palette
              .map(paletteColor => <div style={{
                  backgroundColor: paletteColor,
                  width: '32px',
                  height: '32px',
                  borderRadius: '100px',
                  margin: '3px',
                  border: '0.5px solid #f7f7f7'
                }}></div>)
          }
        </Grid>
        <Button onClick={launchBrand} variant='outlined' size='large' style={{
            // backgroundColor: 'white',
            // boxShadow: '',
            margin: '25px'
          }}>Open Brand</Button>
      </Grid>
      {iconBar}
      {finalDialog}
      {shareDialog}
      {snackbar}
    </Grid>
  </Card>);
}

// <Button variant='contained' color='primary' style={{
//     margin: '15px'
//   }}>View Guide</Button>
