import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import {navigate} from "gatsby"

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: '#f50057',
    width: '330px',
    height: '400px',
    padding: '5px',
    // boxShadow: '0 5px 20px rgba(0,0,0,0.1)',
    transition: 'all 0.2s cubic-bezier(.25,.8,.25,1)',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#ff4586',
      // boxShadow: '0 8px 20px 5px rgba(0,0,0,0.15)',
      cursor: 'pointer',
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      width: '80vw',
      // margin: '20px',
      height: '180px'
    }
  },
  heading: {
    color: 'white',
    padding: '15px',
    fontFamily: 'Work Sans',
    fontWeight: 800,
    fontSize: 40,
    lineHeight: 1,
    // color: 'white',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: 30,
      // paddingBottom: '20px'
    }
  },
  detail: {
    padding: '8px'
  },
  chip: {
    margin: '5px'
  },
  button: {
    // margin: '8px 0px 0px'
  }
}));

export default function ExampleCard(props) {
  // const theme = useTheme();

  // const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();

  // let drinkImageUrl = `https://logo.clearbit.com/${props.drinkData.domain}?size=80`

  return (<Card className={classes.card} variant='' elevation='0'>
    <Grid container="container" direction="column" justify="center" alignItems="center" style={{
        // backgroundColor: '',
        height: '100%',
        padding: '40px 20px'
      }}>
      <Typography className={classes.heading} align='center' utterBottom="utterBottom" variant="h5" component="h2">
        New Style Guide
      </Typography>
    </Grid>
  </Card>);
}

// <Button variant='contained' color='secondary' style={{
//     margin: '15px'
//   }}>Start Now</Button>
